// common
import Dialogue from "@/components/common/dialogue_client.vue"
const GroupList = resolve => require(["@/components/event/_group_list.vue"], resolve)

export default {
    props: ['id', 'type', 'listItem', 'individual', 'leaderBoardSetting'],
    components: {
        Dialogue,
        GroupList
    },
    data: function() {
        return {
            // 下拉選單
            counties: [],
            districts: [],
            schoolList: [],
            // 活動資料
            eventInfo: {
                id:'',
                selectedGroup: {
                    id: '',
                    name: ''
                },
                selectedCounty: '',
                selectedDistrict: '',
                selectedSchoolId: '',
                inputSchoolName: '',
                selectedGrade: '',
                inputName: ''
            },
            oldEventInfo: {
                selectedGroup: {
                    id: '',
                    name: ''
                }
            },

            isShowGroupList: false,
            isRenderGroupList: 0,

            isSetDataReady: {
                getCountyDistrict: false
            },

            isPostingApi: {
                adding: false,  // 報名
                editing: false, // 編輯
            }
        }
    },
    computed: {
        countyDistrict() {
            return this.$store.state.common.countyDistrict;
        },
        grades() {
            let list = [
                {
                    value: 1,
                    text: '一年級',
                },
                {
                    value: 2,
                    text: '二年級',
                },
                {
                    value: 3,
                    text: '三年級',
                },
                {
                    value: 4,
                    text: '四年級',
                },
                {
                    value: 5,
                    text: '五年級',
                },
                {
                    value: 6,
                    text: '六年級',
                },
                {
                    value: 7,
                    text: '七年級',
                },
                {
                    value: 8,
                    text: '八年級',
                },
                {
                    value: 9,
                    text: '九年級',
                },
                {
                    value: 10,
                    text: '十年級',
                },
                {
                    value: 11,
                    text: '十一年級',
                },
                {
                    value: 12,
                    text: '十二年級',
                },
            ];

            let result = [];
            if (this.individual.gradeNameList.length == 0) {
                result = list;
            } else {
                for (let el of list) {                    
                    if (this.individual.gradeNameList.includes(el.text)) {
                        result.push(el);
                    }
                }
            }

            return result;
        },
        updateEventInfoData() {
            let updateData = {};
            let excludeKeys = ['id', 'selectedGroup', 'selectedGrade'];  // 排除檔案
            updateData = this.$_.omitBy(this.$_.omit(this.eventInfo, excludeKeys), (v, k) => {
                return this.oldEventInfo[k] === v;
            });

            if (this.eventInfo.selectedGrade.value != this.oldEventInfo.selectedGrade.value) {
                updateData.selectedGrade = this.eventInfo.selectedGrade;
            }

            return updateData;
        },
        isEventInfoDisable() { // 檢查訂單參數是否有沒填的
            if (!this.eventInfo.selectedGroup.name ||
                !this.eventInfo.selectedCounty ||
                !this.eventInfo.selectedDistrict ||
                !this.eventInfo.inputSchoolName ||
                !this.eventInfo.inputName ||
                (this.type == 'edit' && this.$_.isEmpty(this.updateEventInfoData))) {
                    return true;
                }
            return false;
        }
    },
    methods: {
        dialougeLoadedHandler() {
            // 一開啟彈窗時
            $(`#${this.id}`).on('show.bs.modal', () => {
                this.initialize();
            });
        },
        async initialize() {
            this.$store.dispatch('common/getCountyDistrict')
                .then(() => {
                    this.isSetDataReady.getCountyDistrict = true;
                    let tempCounties = this.leaderBoardSetting.counties.length > 0 ? this.leaderBoardSetting.counties : this.countyDistrict.counties;
                    this.counties = tempCounties;
                    this.schoolList = [];
                    // 只有一個城市選項，直接選
                    if (this.counties.length == 1) {
                        this.eventInfo.selectedCounty = this.counties[0];
                        this.getDistrict();
                    }
                    this.schoolList = [];
                    if (this.type == 'add') {
                        this.addingInfo();
                    }
                    if (this.type == 'edit') {
                        this.editingInfo();
                    }
                })
                .catch(error => console.error('catched error: ' + error));
        },
        addingInfo() {
            // 初始化參數
            this.eventInfo = {
                    id:'',
                    selectedGroup: {
                        id: '',
                        name: ''
                    },
                    selectedCounty: '',
                    selectedDistrict: '',
                    selectedSchoolId: '',
                    inputSchoolName: '',
                    inputName: ''
                }
        },
        editingInfo() {
            this.eventInfo.id = this.listItem.id;
            this.eventInfo.selectedGroup = this.listItem.selectedGroup;
            this.eventInfo.selectedCounty = this.listItem.selectedCounty;
            this.eventInfo.selectedDistrict = this.listItem.selectedDistrict;
            this.eventInfo.inputSchoolName = this.listItem.inputSchoolName;
            this.eventInfo.selectedGrade = { value: this.listItem.selectedGrade, text: this.grades.find(item => item.value == this.listItem.selectedGrade).text };
            this.eventInfo.inputName = this.listItem.inputName;

            if (this.eventInfo.selectedCounty) {
                this.getDistrict();
                this.eventInfo.selectedDistrict = this.listItem.selectedDistrict;
            }

            this.oldEventInfo = this.$_.cloneDeep(this.eventInfo);
        },
        getDistrict(){
            this.eventInfo.selectedDistrict = '';
            this.districts = [];
            this.districts = this.countyDistrict.districts[this.eventInfo.selectedCounty];
        },
        getSchoolList() {
            let params = {
                    county: this.eventInfo.selectedCounty,
                    district: this.eventInfo.selectedDistrict
                }

            this.$httpRequest.get('/api/user/get_school_list', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.schoolList = result;
                        }
                    }
                })
                .catch(function (error) {
                    console.error('catched error: ' + error);
                });
        },
        chooseSchool(item) {
            this.schoolList = [];
            this.eventInfo.selectedSchoolId = item.school_id;
            this.eventInfo.inputSchoolName = item.name;
        },
        addEvent() {
            if (this.isEventInfoDisable || this.isPostingApi.addEvent) {
                return;
            }

            let params = {
                    alias: this.$route.params.alias,
                    isRegister: true,
                    groupInfo: {
                        groupId: this.eventInfo.selectedGroup.id,
                        name: this.eventInfo.inputName
                    }
                }

            if (this.eventInfo.selectedSchoolId != 0) {
                params.groupInfo.schoolId = this.eventInfo.selectedSchoolId;
            }

            this.isPostingApi.addEvent = true;

            this.$httpRequest.post('/api/event/register_event', params)
                .then(response => {
                    this.isPostingApi.addEvent = false;
                    if (response.data.state == 'OK') {
                        let result = response.data.result;
                        if (result) {
                            this.$router.push('/event/' + this.$route.params.alias);
                            $('#addEventDialogue').modal('hide');
                            this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                        }
                    }
                })
                .catch(error => {
                    console.error('catched error: ' + error);
                });
        },
        editEvent() {
            let info = this.updateEventInfoData,
                params = {
                    alias: this.$route.params.alias,
                    egId : this.listItem.id,
                    egInfo : {
                        groupId: this.eventInfo.selectedGroup.id
                    }
                },
                eventInfo = {
                    schoolName: '',
                    name: ''
                }

            if (info.selectedSchoolId) {
                params.egInfo.schoolId = this.eventInfo.selectedSchoolId;
                eventInfo.county = this.eventInfo.selectedCounty;
                eventInfo.district = this.eventInfo.selectedDistrict;
                eventInfo.schoolName = this.eventInfo.inputSchoolName;
            }
            if (info.selectedGrade) {
                params.egInfo.grade = info.selectedGrade.value;
                eventInfo.grade = info.selectedGrade.value;
            }
            if (info.inputName) {
                params.egInfo.name = info.inputName;
                eventInfo.name = info.inputName;
            }

            this.isPostingApi.editing = true;
            this.$httpRequest.post('/api/event/update_group_info', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        this.$parent.resetTeamList(eventInfo);
                        this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                    }
                    this.isPostingApi.editing = false;
                    $('#editEventDialogue').modal('hide');
                })
                .catch(function (error) {
                    console.error('catched error: ' + error);
                    this.isPostingApi.editing = false;
                    $('#editEventDialogue').modal('hide');
                });
        }
    }
}