import Layout from "@/layouts"
// common
import Dialogue from "@/components/common/dialogue_client.vue"
const MobileUnsupported = resolve => require(["@/components/common/mobile_unsupported.vue"], resolve)
// components
// regulation短期做法，未來需求收斂後再改由後端傳值
const Regulation1 = resolve => require(["@/components/event/regulation1.vue"], resolve) // 活動辦法
const Regulation2 = resolve => require(["@/components/event/regulation2.vue"], resolve)
const Help1 = resolve => require(["@/components/event/help1.vue"], resolve) // Q&A
const Help2 = resolve => require(["@/components/event/help2.vue"], resolve)
const TeamCharts = resolve => require(["@/components/event/charts_team.vue"], resolve)
const IndividualCharts = resolve => require(["@/components/event/charts_individual.vue"], resolve)
const MyRegister = resolve => require(["@/components/event/my_register.vue"], resolve)
const TeamRegister = resolve => require(["@/components/event/team_register.vue"], resolve)
const PersonalRegister = resolve => require(["@/components/event/personal_register.vue"], resolve)

export default {
    name: 'Event',
    components: {
        Layout,
        Dialogue,
        MobileUnsupported,
        Regulation1,
        Regulation2,
        Help1,
        Help2,
        TeamCharts,
        IndividualCharts,
        MyRegister,
        TeamRegister,
        PersonalRegister
    },
    data: function() {
        return {
            eventInfo: {},
            registerStatusText: '',
            oranginalAlias: '',
            individual: { // 活動跟個人相關參數
                allowRegister: false,
                isRegister: false,
                grade: '',
                gradeNameList: [],
                countyNameList:[],
                instructions: '',
            },

            isSetDataReady: {
                getEventInfo: false  // 取得目前活動資訊
            },
        }
    },
    watch: {
        userInfo(newValue) {
            // 未登入 -> 已登入
            if (!this.$_.isEmpty(newValue)) {
                this.getEventInfo();
            }
        },
        oranginalAlias(newValue) {
            if (newValue) {
                this.getEventInfo();
            }
        }
    },
    computed: {
        windowWidth() {
            return this.$store.state.common.windowWidth;
        },
        userInfo() {
            return this.$store.state.common.userInfo;
        },
        layout() {
            return this.$store.state.common.layout;
        },
        isCertificateStart() {
            if (this.eventInfo.certificateStart != '') {    // 如果沒設時間會是空的
                let nowDate = new Date(),
                certificateDate = new Date(this.eventInfo.certificateStart);
                if (nowDate >= certificateDate) {
                    return true;
                }
            }
            
            return false;
        },
        componentType() {
            this.oranginalAlias = this.$route.params.alias;
            // 規則
            if (!this.$route.params.page) {
                return 'Regulation' + this.$route.params.alias; // 僅限ID
            }

            // Q&A
            if (this.$route.params.page === 'help') {
                return 'Help' + this.$route.params.alias; // 僅限ID
            }

            // 排行榜
            if (this.$route.params.page === 'charts') {
                return `${this.$route.params.chartsType}-charts`;
            }

            return this.$route.params.page;
        },
        isAllowRegister() {
            if (this.individual.isPublish != '1') {
                this.registerStatusText = '活動已下架';
                return false;
            }

            // 沒輸入時間
            if (this.eventInfo.registerStart == '' || this.eventInfo.registerEnd == '') {
                this.registerStatusText = '報名尚未開始';
                return false;
            }
            
            let nowDate = new Date(),
                registerStart = new Date(this.eventInfo.registerStart),
                registerEnd = new Date(this.eventInfo.registerEnd);
            
            if (nowDate > registerEnd) {
                this.registerStatusText = '報名已結束';
                return false;
            } else if (nowDate < registerStart) {
                this.registerStatusText = '報名尚未開始';
                return false;
            } else if (nowDate > registerStart && nowDate < registerEnd) {  // 後台沒防呆，避免 registerEnd < registerStart, 多做一下判斷
                this.registerStatusText = '我要報名';
                return true;
            } else {
                this.registerStatusText = '---';
                return false;
            }
        }
    },
    created: function() {
        if (this.layout.name !== 'LayoutDefault') return;

        this.$store.commit('common/setHeaderActiveLink', 'event');
        this.$store.commit('common/setIsAdminPage', false);

        this.getEventInfo();
    },
    methods: {
        getEventInfo() {
            let params = {
                alias: this.$route.params.alias
            }

            this.$httpRequest.get('/api/event/get_event', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.eventInfo = {
                                name: result.info.eventInfo.name,
                                description: result.description,
                                wordpressUrl: result.info.eventInfo.header.left.link,//result.wordpress_url,
                                qaUrl: result.qa_url,
                                registerStart: this.$util.unixTimestampToDatetime(result.info.eventInfo.duration.register.start, this.$util.getBrowserCurrentTimeZone()),
                                registerEnd: this.$util.unixTimestampToDatetime(result.info.eventInfo.duration.register.end, this.$util.getBrowserCurrentTimeZone()),
                                certificateStart: this.$util.unixTimestampToDatetime(result.info.certificateSetting.downloadTime),
                                backgroundImage: result.cover,
                                headerInfo: result.info.eventInfo.header,           // 上方header資訊
                                leaderBoardSetting: result.info.leaderBoardSetting,
                                contentStyle: result.info.eventInfo.contentStyle,    
                            }
                            this.eventInfo.leaderBoardSetting.counties = result.info.registerSetting.scope.counties;
                            this.individual.countyNameList = result.info.registerSetting.scope.counties;
                            this.individual.allowRegister = result.is_purchased; // 是否允許註冊(有購買)
                            this.individual.isPublish = result.publish;
                            this.individual.isRegister = result.is_register;
                            this.individual.grade = result.grade;
                            this.individual.gradeNameList = result.info.registerSetting.scope.grades;
                            this.individual.instructions = result.info.eventInfo.instructions.split('\n');
                        }
                        this.isSetDataReady.getEventInfo = true;
                    }
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },

        checkUserLogin() {
            if (this.$_.isEmpty(this.userInfo)) {
                $('#loginDialogue').modal('show');
                return false;
            }
            return true;
        },
    }
}
