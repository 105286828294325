// common
import Dialogue from "@/components/common/dialogue_client.vue"

export default {
    props: ['isAllowRegister', 'registerStatusText', 'individual', 'contentStyle'],
    components: {
        Dialogue
    },
    data: function() {
        return {
            selectedGrade: '',
            isPostingApi: {
                individualRegistering: false
            },
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.common.userInfo;
        },
        grades() {
            let list = [
                {
                    value: 1,
                    text: '一年級',
                },
                {
                    value: 2,
                    text: '二年級',
                },
                {
                    value: 3,
                    text: '三年級',
                },
                {
                    value: 4,
                    text: '四年級',
                },
                {
                    value: 5,
                    text: '五年級',
                },
                {
                    value: 6,
                    text: '六年級',
                },
                {
                    value: 7,
                    text: '七年級',
                },
                {
                    value: 8,
                    text: '八年級',
                },
                {
                    value: 9,
                    text: '九年級',
                },
                {
                    value: 10,
                    text: '十年級',
                },
                {
                    value: 11,
                    text: '十一年級',
                },
                {
                    value: 12,
                    text: '十二年級',
                },
            ];

            let result = [];
            if (this.individual.gradeNameList.length == 0) {
                result = list;
            } else {
                for (let el of list) {                    
                    if (this.individual.gradeNameList.includes(el.text)) {
                        result.push(el);
                    }
                }
            }

            return result;
        },
    },
    created: function() {
        if (!this.$parent.$parent.checkUserLogin()) {
            return;
        }
        else {
            if (this.userInfo.permission !== 'user') {
                this.$router.push('/event/' + this.$route.params.alias + '/charts/individual');
            }
        }

        this.selectedGrade = this.individual.grade ? { value: this.individual.grade, text: this.grades[this.individual.grade - 1].text }
                    : this.userInfo.grade ? { value: this.userInfo.grade.replace(/^0+/, ''), text: this.grades[this.userInfo.grade.replace(/^0+/, '') - 1].text } : '';

        if (this.individual.isPublish != '1') {
            this.$store.dispatch('common/setAlert', { msg: '活動已下架', status: 'danger', duration: '5000' });
        }
    },
    methods: {
        showIndividualRegisterDialogue() {
            if (!this.$parent.$parent.checkUserLogin()) {
                return;
            }

            if (!this.individual.allowRegister) {
                this.$store.dispatch('common/setAlert', { msg: '請購買活動相關課程後才能參加活動', status: 'danger' });
                return;
            }

            $('#eventConfirmDialogue').modal('show');
        },
        individualRegister() {
            let params = {
                alias: this.$route.params.alias,
                isRegister: this.individual.isRegister ? false : true,
                grade: this.selectedGrade.value,
            }

            this.isPostingApi.individualRegistering = true;
            this.$httpRequest.post('/api/event/register_event', params)
                .then(response => {
                    this.isPostingApi.individualRegistering = false;
                    if (response.data.state == 'OK') {
                        let result = response.data.result;
                        if (result) {
                            this.individual.isRegister = !this.individual.isRegister;
                            this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                        }
                    }
                    $('#eventConfirmDialogue').modal('hide');
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        }
    }
}